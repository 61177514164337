import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getInfo, modalActions} from '../../../actions/index';
import { withTranslation } from 'react-i18next';
import { AiTwotoneEdit } from "react-icons/ai";

class FuelPrices extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_corporate_sell_price({}, "get_corporate_sell_price"))
    }

    onBtnClick = (event) => {
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.currentTarget.getAttribute('data_modal'), {fuelType: this.props.corpUserPrices}))
    }

    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
        const {corpUserPrices} =this.props;
		const { t } = this.props;

        return (
            <div className="main_container">
                <div className="balance">
                    <h1>{t('fuel_prices')}</h1>
                </div>
                <div className="row">
                        <div className="cont2 mod_dashb">
                            <div className="widget color2">
                                <div className="table-responsive">
                                    {(userRole === 'admin2') && <button
                                        className="btn btn-info"
                                        style={{marginBottom: '10px'}}
                                        onClick={this.onBtnClick }
                                        data_modal="EditFuelPrices"
                                    ><AiTwotoneEdit size={20}/>
                                        {t('edit_fuel_type')}</button>}
                                    <table className="table table-striped">
                                          <thead>
                                            <tr>
                                                <th>{t('fuel_type')}</th>
                                                <th>{t('changed_date')}</th>
                                                <th>{t('unit')}</th>
                                                <th>{t('old_price')}</th>
                                                <th>{t('fuel_price')}</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {corpUserPrices && corpUserPrices.map((item, index)=>
                                              <tr key={item.fueltypeid}>
                                                  <td><div className='table_head_txt'>{t('fuel_type')}:</div>{item.name}</td>
                                                  <td><div className='table_head_txt'>{t('changed_date')}:</div>{item.updatedate}</td>
                                                  <td><div className='table_head_txt'>{t('unit')}:</div>{item.fuelunit}</td>
                                                  <td><div className='table_head_txt'>{t('old_price')}:</div>{item.oldprice}</td>
                                                  <td><div className='table_head_txt'>{t('fuel_price')}:</div>{item.price}</td>
                                              </tr>
                                          )}

                                          </tbody>
                                      </table>
                                </div>
                            </div>
                        </div>

                    </div>


            </div>
        )
    }
}
function mapStateToProps(state) {
    const {corpUserPrices} = state.get_corporate_sell_price
    return {
        corpUserPrices,
    };
}

const connectedFuelPrices = connect(mapStateToProps)(withTranslation()(FuelPrices));
export { connectedFuelPrices as FuelPrices };